import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import DefaultLayout from "../../components/Layout/DefaultLayout";

import { alertFailure } from "../../store/actions/alert";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import SearchForm from "../UserList/SearchForm";
import {
  getDisclaimerListById,
  getUserTermsList,
} from "../../request/disclaimer";
// @ts-ignore
import { NotificationManager } from "react-notifications";
import CopyToClipboard from "react-copy-to-clipboard";
import TermLogsModal from "./modal/TermLogsModal";

const tableHeaderData = [
  "#",
  "Wallet Address",
  "Status",
  "Accepted At",
  "Updated At",
  "Actions",
];

const UserTermsLogs: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [usersTermsList, setUsersTermsList] = useState([]);
  const [selectedTier, setSelectedTier] = useState(-1);
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [viewTerms, setViewTerm] = useState<any>("");
  const [isTermLogModalOpen, setIsTermLogModalOpen] = useState(false);

  const handleTermLogOpen = () => {
    setIsTermLogModalOpen(true);
  };
  const handleTermLogClose = () => {
    setIsTermLogModalOpen(false);
  };

  const getUserTermsInfo = async (query: any) => {
    const queryParams: any = {
      searchQuery: query,
      page: currentPage,
    };
    if (selectedTier >= 0) {
      queryParams.tier = selectedTier;
    }

    try {
      setLoading(true);
      const resObject = await getUserTermsList(queryParams);
      if (resObject.status === 200) {
        setUsersTermsList(resObject.data.data);
        setLastPage(resObject.data.lastPage);

        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserTermsInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage, selectedTier]);

  const maskWalletAddress = (wallet: string) => {
    const preWalletLength = wallet.length;

    let r = wallet.slice(5, preWalletLength - 5);
    wallet = wallet.replace(r, "*****");

    return wallet;
  };

  const handleViewTerms = async (_id: any) => {
    const response = await getDisclaimerListById(_id);
    if (response.status === 200) {
      let content = JSON.parse(response?.data?.content);
      setViewTerm(content);
      handleTermLogOpen();
    } else {
      dispatch(alertFailure("fetching view record...."));
    }
  };

  return (
    <DefaultLayout>
      <>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            maxWidth: "100%",
            marginTop: "15px",
            marginLeft: "12px",
          }}
        >
          <SearchForm seachValue={query} handleSearch={handleSearch} />
        </Grid>

        <TableContainer component={Paper} className={classes.tableContainer}>
          {loading ? (
            [...Array(10)].map((num, index) => (
              <div key={index}>
                <Skeleton className={classes.skeleton} width={"100%"} />
              </div>
            ))
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaderData.map((tableHeader: string, index: number) => (
                    <TableCell key={index} className={classes.tableHeader}>
                      {tableHeader}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {usersTermsList && usersTermsList.length > 0 ? (
                  usersTermsList.map((terms: any, index: number) => (
                    <TableRow className={classes.tableRow} key={terms.id}>
                      <TableCell className={classes.tableCell} align="left">
                        <span className={`${classes.tableCellSpan}`}>
                          {terms.id}
                        </span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>{maskWalletAddress(terms.wallet_address)}</span>
                          <CopyToClipboard
                            onCopy={() => NotificationManager.success("Copied")}
                            text={terms.wallet_address}
                          >
                            <Button style={{ minWidth: "20px" }}>
                              <img
                                src="/images/icon-copy.svg"
                                alt=""
                                style={{ maxHeight: "18px" }}
                              />
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <span className={`${classes.tableCellSpan}`}>
                          {terms?.status[0].toUpperCase() +
                            terms?.status.slice(1).toLowerCase()}
                        </span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <span className={`${classes.tableCellSpan}`}>
                          {terms?.accepted_at || "-"}
                        </span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <span className={`${classes.tableCellSpan}`}>
                          {terms?.updated_at || "-"}
                        </span>
                      </TableCell>

                      <TableCell className={classes.tableCell} align="left">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleViewTerms(terms.terms_id);
                            }}
                          >
                            View
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          )}
          {failure ? (
            <p className={classes.errorMessage}>{failure}</p>
          ) : (!usersTermsList || setUsersTermsList.length === 0) &&
            !loading ? (
            <p className={classes.noDataMessage}>There is no data</p>
          ) : (
            <>
              {setUsersTermsList &&
                setUsersTermsList.length > 0 &&
                lastPage > 1 && (
                  <Pagination
                    page={currentPage}
                    className={classes.pagination}
                    count={lastPage}
                    onChange={handlePaginationChange}
                  />
                )}
            </>
          )}
        </TableContainer>

        {isTermLogModalOpen && (
          // @ts-ignore
          <TermLogsModal
            open={isTermLogModalOpen}
            onCancel={handleTermLogClose}
            title="Terms Logs"
            children={
              <div
                style={{
                  padding: "60px 0px",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: viewTerms,
                  }}
                ></span>
              </div>
            }
          />
        )}
      </>
    </DefaultLayout>
  );
};

export default UserTermsLogs;
