import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import UserRow from "./UserRow";
import SearchForm from "./SearchForm";
import { exportUsersStakingList, getUserStakedInfo } from "../../request/user";
import Grid from "@material-ui/core/Grid";
import { apiRoute } from "../../utils";
import { BaseRequest } from "../../request/Request";

const tableHeaders = [
  "WALLET ADDRESS",
  "POOL NAME",
  "POOL TYPE",
  "POOL DURATION",
  "STAKED AMOUNT",
  "CGPT LOCKED",
  "UPDATED AT",
];

const fetchListPool = async () => {
  let url = apiRoute(`/staking-pool`);

  try {
    const baseRequest = new BaseRequest();
    const response = (await baseRequest.get(url)) as any;
    const resObject = await response.json();

    if (resObject.status === 200) {
      const data = resObject.data;
      console.log(data);
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

const UserStakingInfo: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [poolDuration, setPoolDuration] = useState(-1);
  const [locked, setIsLocked]: any = useState(false);

  const [stakingPools, setStakingPools] = useState<any>([]);

  useEffect(() => {
    fetchListPool().then((data) => {
      setStakingPools(data);
    });
  }, []);

  const getUserListInfo = useCallback(
    async (query: any) => {
      const queryParams: any = {
        search_text: query,
        page: currentPage,
        limit: 10,
      };
      if (Number(poolDuration) >= 0) {
        queryParams.staking_pool_id = poolDuration;
      }

      if (locked) {
        queryParams.is_locked = locked ? 1 : 0;
      }

      try {
        setLoading(true);
        const resObject = await getUserStakedInfo(queryParams);
        if (resObject.status === 200) {
          setUsers(resObject.data.data);
          setLastPage(resObject.data.lastPage);
          setFailure(false);
        } else {
          setFailure(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailure(true);
      }
    },
    [currentPage, locked, poolDuration]
  );

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage, getUserListInfo]);

  const handleExportCSV = async () => {
    try {
      await exportUsersStakingList();
      setQuery("");
    } catch (e) {
      console.log(e);
    }
  };

  const handlePoolDurationChange = (event: any) => {
    setQuery("");
    setCurrentPage(1);
    setPoolDuration(Number(event.target.value));
  };

  const handleLockedChange = async (event: any) => {
    const { checked } = event.target;
    setIsLocked(checked);
  };
  return (
    <DefaultLayout>
      <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
        <Switch checked={locked ? true : false} onChange={handleLockedChange} />
        <div>
          <h6 style={{ margin: 0 }}>Locked</h6>
        </div>
      </FormControl>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "100%",
          marginTop: "15px",
        }}
      >
        <button
          className={classes.exportBtn}
          style={{ color: "#000", marginLeft: "10px" }}
          onClick={handleExportCSV}
          disabled={users?.length <= 0}
        >
          Export to CSV
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={classes.formControl}>
            <Select
              name="setPoolDuration"
              value={poolDuration}
              onChange={handlePoolDurationChange}
              className={classes.formControlInput}
            >
              <MenuItem value={-1}>Pool Duration</MenuItem>
              {stakingPools.map((value : any, index : any) => {
                return (
                  <MenuItem key={index} value={value.pool_id}>
                    {value.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <SearchForm seachValue={query} handleSearch={handleSearch} />
        </div>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={"100%"} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {users &&
                users.length > 0 &&
                users.map((user: any, index: number) => (
                  <UserRow
                    key={user.id}
                    user={user}
                    getUserListInfo={getUserListInfo}
                  />
                ))}
            </TableBody>
          </Table>
        )}
        {failure ? (
          <p className={classes.errorMessage}>{failure}</p>
        ) : (!users || users.length === 0) && !loading ? (
          <p className={classes.noDataMessage}>There is no data</p>
        ) : (
          <>
            {users && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
    </DefaultLayout>
  );
};

export default UserStakingInfo;
